import React, { Fragment, useState, useRef } from 'react'
import '../../css/AdminModal.css'
import { motion } from 'framer-motion'
import { useAdmin } from '../../contexts/AdminCtx'

const AdminModal = (props) => {
    
    const admin = useAdmin()
    const { activeTab, buttonClicked } = admin.state
    const { setShowModal } = props
    
    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const handleChange = (e) => {
        if (e.target.name === 'userName') {
            setUserName(e.target.value)
        }
        if (e.target.name === 'password') {
            setPassword(e.target.value)
        }
        if (e.target.name === 'confirmPassword') {
            setConfirmPassword(e.target.value)
        }
    }

    const handleClickOut = (e) => {
        if (e.target.id === 'outsideAdminModal') {
            admin.dispatch({type: 'BUTTON_CLICKED', buttonClicked: null})
            setShowModal(false)
        }
    }

    return (
        <motion.div
            key='outsideAdminModal'
            id='outsideAdminModal' 
            className='AdminModalContainer'
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{ delay: .25, duration: .5}}
            onClick={(e) => handleClickOut(e)}
        >
            {activeTab === 'Users' && (
                <Fragment>
                    {buttonClicked === 'ADD_USER' && (
                        <div className='AdminModal' id='insideAdminModal'>
                            <div className='AdminModalTitle'>
                                Add User
                            </div>
                            <div className='AdminModalBody'>
                                <div>
                                    <label for='userName'>Username</label>
                                    <input 
                                        className='AdminInput InputNoAppend'
                                        name='userName'
                                        id='userName'
                                        type='text'  
                                        autoFocus={true}
                                        placeholder='Enter username...'
                                        onChange={(e) => handleChange(e)}
                                        onPaste={(e) => handleChange(e)}
                                        value={userName}
                                        minLength={4}
                                        maxLength={25}
                                    />
                                </div>
                                <div>
                                    <label for='password'>Password</label>
                                    <input 
                                        className='AdminInput InputNoAppend'
                                        name='password'
                                        type='password'  
                                        placeholder='Enter password...'
                                        onChange={(e) => handleChange(e)}
                                        onPaste={(e) => handleChange(e)}
                                        value={password}
                                        minLength={4}
                                        maxLength={25}
                                    />
                                </div>
                                <div>
                                    <label for='confirmPassword'>Confirm</label>
                                    <input 
                                        className='AdminInput InputNoAppend'
                                        name='confirmPassword'
                                        type='password'  
                                        placeholder='Confirm password...'
                                        onChange={(e) => handleChange(e)}
                                        onPaste={(e) => handleChange(e)}
                                        value={confirmPassword}
                                        minLength={4}
                                        maxLength={25}
                                    />
                                </div>
                            </div>
                            <div className='AdminModalButtonRow'>
                                    <button 
                                        className='AdminModalButton Button'
                                        
                                        >Add</button>
                                    <button 
                                        className='AdminModalButton Button'
                                        
                                        >Cancel</button>
                            </div>
                        </div>
                    )}
                </Fragment>
            )}
        </motion.div>
    )
}

export default AdminModal