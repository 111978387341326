import React from 'react'
import '../css/ClientModal.css'
import { motion } from 'framer-motion'

const ClientModal = () => {

    const handleDownload = () => {
        window.location.href = 'https://www.dropbox.com/s/1khrgd437tylyfe/ZebraBrowserPrintSetup-1.2.1.279.exe?dl=1'
    }

    const handleReload = () => {
        window.location.reload(false)
    }
    return (
        <motion.div 
            className='ClientModalContainer'
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{ delay: 1, duration: 1}}
        >
            <div className='ClientModal'>
                <div className='ClientModalTitle'>
                    Browser Print Not Detected
                </div>
                <div className='ClientModalBody'>
                    <div className='ClientModalBodyRow'>CaseLink Pro requires the Zebra Browser Print software to be installed and running</div>
                    <div className='ClientModalBodyRow'>Don't have Browser Print installed?  Click "Download" to get the installer</div>
                    <div className='ClientModalBodyRow'>Already have Browser Print installed?  Start the application and then click "Reload"</div>
                </div>
                <div className='ClientModalButtonRow'>
                        <button 
                            className='ClientModalButton Button Shadow'
                            onClick={handleDownload}
                            >Download</button>
                        <button 
                            className='ClientModalButton Button Shadow'
                            onClick={handleReload}
                            >Reload</button>
                </div>
            </div>
        </motion.div>
    )
}

export default ClientModal