//Set up store for global state

import React, {createContext, useContext, useReducer} from 'react'
import { format } from 'date-fns'

const initialState = {
    isDone: false,
    activeTab: 'TabCase',
    firstURL: '',
    firstRef: {},
    lastURL: '',
    firstCount: 0,
    lastCount: 0,
    totalLabels: 0,
    rows: [],
    labelData: {
        caseID: '',
        caseCode: '',
        facilityID: null,
        facilityName: '',
        userID: null,
        packDate: '',
        shortDate: '',
        quantity: ''
    },
    labelZPL: '',
    isLoading: false,
    syncStatus: 'Synced',
    showHelp: false
}

const store = createContext(initialState)
const { Provider } = store

const reducer = (state, action) => {

    switch(action.type) {
        
        case 'SET_SYNC_STATUS':
            return { ...state, syncStatus: action.syncStatus}

        case 'SET_ACTIVE_TAB':
            if (action.hasOwnProperty('isDone')) {
                return { ...state, activeTab: action.activeTab, isDone: action.isDone}
            } else {
                return { ...state, activeTab: action.activeTab }
            }
            
        case 'ADD_ROW':
            return { 
                ...state, 
                rows: [
                    ...state.rows, 
                    {...action.rowData}
                ], 
                firstURL: '', 
                lastURL: '', 
                firstCount: 0, 
                lastCount: 0, 
                totalLabels: action.newTotal
            }

        case 'DELETE_ROW':
            return { ...state, rows: state.rows.filter((_, i) => i !== action.index), totalLabels: action.newTotal }

        case 'UPDATE_FIRST_URL':
            return { ...state, firstURL: action.firstURL }

        case 'UPDATE_LAST_URL':
            return { ...state, lastURL: action.lastURL }

        case 'UPDATE_FIRSTREF':
            return { ...state, firstRef: action.refObj}

        case 'UPDATE_HISTORYREF':
            return { ...state, historyRef: action.refObj}

        case 'UPDATE_COUNT':
            return { ...state, firstCount: action.firstCount, lastCount: action.lastCount }

        case 'GENERATE_LABEL':
            return { 
                ...state,
                isDone: true, 
                labelData: { 
                    ...state.labelData, 
                    caseID: action.caseID,
                    caseCode: action.caseID.slice(0, 8), 
                    quantity: state.totalLabels,
                    packDate: new Date(),
                    shortDate: format(new Date(), 'MM/dd/yyyy')
                }}

        case 'LOAD_LABEL':
            return {
                ...state,
                firstRef: {},
                isDone: true,
                labelData: {
                    ...state.labelData,
                    caseID: action.caseID,
                    caseCode: action.caseID.slice(0, 8),
                    quantity: action.quantity,
                    packDate: new Date(action.date),
                    shortDate: format(new Date(action.date), 'MM/dd/yyyy')
                }
            }
        
        case 'WRITE_LABEL':
            return { ...state, labelZPL: action.labelZPL }
        
        case 'TOGGLE_HELP':
            return { ...state, showHelp: !state.showHelp }

        case 'START_LOADING':
            return { ...state, isLoading: true }
        
        case 'END_LOADING':
            return { ...state, isLoading: false }
        
        case 'RESET_STATE':
            return { ...initialState }

        default:
            throw new Error()
    }
}

const StateProvider = ( { children } ) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    return <Provider value={{ state, dispatch }}>{children}</Provider>
}


export default StateProvider
export const useStore = () => useContext(store)