import React, { useState, useEffect } from 'react'
import { getHistory } from '../../utils/database/dbFunction'
import { useUser } from '../../contexts/UserCtx'
import { useStore } from '../../Store'
import { useLanguage } from '../../contexts/LanguageCtx'
import { format } from 'date-fns'

const HistoryTable = (props) => {

    const { offset, setRowTotal } = props
    const user = useUser()
    const language = useLanguage()
    const activeLanguage = {...language.state}
    const globalState = useStore()
    const [rows, setRows] = useState([])

    const handleLoad = (event) => {
        const code = event.currentTarget.children[2].innerHTML
        console.log(code)
        const index = rows.findIndex(row => row._id.slice(0,8) === code)
        if (index >= 0) {
            const { _id, facilityID, facilityName, quantity, date } = rows[index]
            globalState.dispatch({type: 'LOAD_LABEL', caseID: _id, facilityID, facilityName, quantity, date})
        }
    }

    useEffect(() => {
        const getData = async () => {
            try {
                const result = await getHistory(user.state.userName, offset)
                if (result) {
                    setRows(result)
                    setRowTotal(result.length)
                }
                
            } catch (err) {
                console.log(err)
                return
            } 
        }
        getData()
    }, [offset])

    return (
        <div className='HistoryTable'>
            <table id='HistoryTable' style={{width: '100%', height: `${rows.length * 10}%` ,cellPadding: '1rem'}}>
                <thead>
                    <tr>
                    <th style={{width: '33%'}}>{activeLanguage.TableHeaderDate}</th>
                    <th style={{width: '33%'}}>{activeLanguage.TableHeaderTime}</th>
                    <th className='thNoBorder' style={{width: '34%'}}>{activeLanguage.TableHeaderCaseCode}</th>
                    </tr>
                </thead>
                <tbody>
                    
                {rows.length > 0 && rows.map((row, index) => 

                    (
                        <tr 
                            tabIndex={0} 
                            onClick={(e) => handleLoad(e)} 
                            onKeyPress={(e) => handleLoad(e)} 
                            key={index.toString()} 
                            style={index % 2 ? {background: '#dddddd'} : {background: 'white'} }
                        >
                            <td>{format(new Date(row.date), activeLanguage.DateFormat)}</td>
                            <td>{format(new Date(row.date), 'h:mm aaaa')}</td>
                            <td>{row._id.slice(0,8)}</td>
                        </tr>
                    )
                    )}
                
                </tbody>
            </table>
        </div>
    )
}

export default HistoryTable