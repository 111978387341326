import React, { useState } from 'react'
import '../../css/HelpContainer.css'
import { useStore } from '../../Store'
import { motion } from 'framer-motion'
import CloseIcon from '../../images/icons/cancel-white-36dp.svg'
import HelpMenu from './HelpMenu'

const HelpContainer = () => {
    
    const globalState = useStore()
    const [activeTab, setActiveTab] = useState(0)

    const toggleHelp = (event) => {
        event.preventDefault()
        if (event.target === event.currentTarget) {
        globalState.dispatch({type: 'TOGGLE_HELP'})
        }
    }

    return (
        <motion.div 
            className='HelpContainer' 
            onClick={(e) => toggleHelp(e)}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: .25}}
            >
            <motion.div 
                className='HelpWindow'
                initial={{y: '-200%'}}
                animate={{y: 0}}
                exit={{y: '-200%'}}
                transition={{type: 'spring', damping: 16 }}
                >
                <div className='HelpTitle Title'>User Guide</div>
                <button className='HelpCloseWindow' onClick={toggleHelp}>
                    <img tabIndex={0} className='SVGCloseWindow' onClick={toggleHelp} src={CloseIcon} />
                </button>
                <div className='HelpMenu'><HelpMenu activeTab={activeTab} setActiveTab={setActiveTab} /></div>
                <div className='HelpBody'>This is tab {activeTab}</div>
                <div className='HelpControls Controls'>Controls</div>
                
                
                
            </motion.div>
        </motion.div>
    )
}

export default HelpContainer