import React from 'react'
import '../css/WarningContainer.css'

const WarningContainer = () => {

    const handleClick = () => {
        window.location.href = 'https://www.google.com/chrome/'
    }
    return (
        <div className='WarningContainer'>
            <div className='WarningText'>Case Link Pro requires a PC running Microsoft Windows and Google Chrome</div>
            <button 
                className='WarningButton Button Shadow'
                onClick={handleClick}
            >Download Chrome</button>
        </div>
    )
}

export default WarningContainer