import React, { useEffect } from 'react'
import { motion } from 'framer-motion'
import { useAdmin } from '../../contexts/AdminCtx'

const UsersButtons = () => {

    const admin = useAdmin()
    const { userSelected } = admin.state.userTab

    const handleAdd = () => {
        admin.dispatch({type: 'BUTTON_CLICKED', buttonClicked: 'ADD_USER'})
    }
    const handleEdit = () => {
        admin.dispatch({type: 'BUTTON_CLICKED', buttonClicked: 'EDIT_USER'})
    }
    const handleDelete = () => {
        admin.dispatch({type: 'BUTTON_CLICKED', buttonClicked: 'DELETE_USER'})
    }

    return (
        <motion.div 
            className='UsersButtons'
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{ duration: .5, delay: .5 }}
            >
            <button
                className='UsersButton Button Shadow'
                onClick={handleAdd}
                >Add User
            </button>
            <button
                className='UsersButton Button Shadow'
                onClick={handleEdit}
                disabled={!userSelected}
                >Edit User
            </button>
            <button
                className='UsersButton Button Shadow'
                onClick={handleDelete}
                disabled={!userSelected}
                >Delete User
            </button>
        </motion.div>
    )
}

export default UsersButtons