import React from 'react'
import '../css/UpdateModal.css'
import { motion } from 'framer-motion'
import { useServiceWorker } from '../contexts/ServiceCtx'

const UpdateModal = () => {
    const { updateAssets } = useServiceWorker()

    return (
        <motion.div 
            className='UpdateModalContainer'
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{ delay: 1, duration: 1}}
        >
            <div className='UpdateModal'>
                <div className='UpdateModalTitle'>
                    Update Available
                </div>
                <div className='UpdateModalBody'>
                    <div className='UpdateModalBodyRow'>A new version of CaseLink Pro is available</div>
                    <div className='UpdateModalBodyRow'>Please click the Update button below</div>
                </div>
                <div className='UpdateModalButtonRow'>
                        <button 
                            className='UpdateModalButton Button Shadow'
                            onClick={updateAssets}
                            >Update</button>
                </div>
            </div>
        </motion.div>
    )
}

export default UpdateModal