import React, { useEffect, useState } from 'react'
import UsersScreen from './UsersScreen'
import FacilitiesScreen from './FacilitiesScreen'
import AdminModal from './AdminModal'
import { useAdmin } from '../../contexts/AdminCtx'
import '../../css/AdminContainer.css'
import { AnimatePresence } from 'framer-motion'

const AdminContainer = () => {

    const [showModal, setShowModal] = useState(false)
    const adminCtx = useAdmin()
    const { activeTab, tabs, buttonClicked } = adminCtx.state
    const handleMenuClick = (event) => {
        adminCtx.dispatch({type: 'UPDATE_ADMIN_TAB', activeTab: event.target.id})
    }

    useEffect(() => {
        if (buttonClicked) {
            setShowModal(true)
        }
    }, [buttonClicked])

    return (
        <div className='AdminContainer'>
            <AnimatePresence>
            {showModal && (
                <AdminModal key='AdminModal' setShowModal={setShowModal} />
            )}
            </AnimatePresence>
            <div className='AdminTitle Title'>Admin Portal</div>
            <div className='AdminMenu'>
                {tabs.map((tab) => {
                    return (
                        <button
                            className='AdminButton Shadow'
                            onClick={(e) => handleMenuClick(e)}
                            key={tab}
                            id={tab}
                        >{tab}
                        </button>
                    )
                })}
            </div>
            <div className='AdminWindowContainer'>
                <AnimatePresence exitBeforeEnter>
                    {activeTab === 'Users' && (
                        <UsersScreen key='Users' />
                    )}
                    {activeTab === 'Facilities' && (
                        <FacilitiesScreen key='Facilities' />
                    )}
                </AnimatePresence>
            </div>
        </div>
    )
}

export default AdminContainer