import React from 'react'
import NavLeft from './Navbar/NavLeft'
import NavRight from './Navbar/NavRight'
import '../css/NavContainer.css'

const NavContainer = () => {

    return (
        <div className='NavContainer'>
            <NavLeft />
            <NavRight />
        </div>
    )
}

export default NavContainer