import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ServiceWorkerProvider } from './contexts/ServiceCtx'
import './fonts/Quicksand-Regular.woff2'
import './fonts/Quicksand-SemiBold.woff2'
import './fonts/Quicksand-Medium.woff2'

ReactDOM.render(
    <ServiceWorkerProvider>
        <App />
    </ServiceWorkerProvider>
    ,
  document.getElementById('root')
);

