// Packages
import React from 'react'

// Contexts
import { useLanguage } from '../../contexts/LanguageCtx'

/* Component description:
    This is a simple loading spinner that displays when a new label is being generated by the Labelary API
*/
const LabelSpinner = () => {

    const language = useLanguage()
    const activeLanguage = {...language.state}

    return (
        
            <div className='LabelSpinnerWindow'>
                    <div className="sk-folding-cube">
                        <div className="sk-cube1 sk-cube"></div>
                        <div className="sk-cube2 sk-cube"></div>
                        <div className="sk-cube4 sk-cube"></div>
                        <div className="sk-cube3 sk-cube"></div>
                    </div>
                    <div className='SpinnerLoadingText'>{activeLanguage.LabelLoading}</div>
                </div>
        
    )
}

export default LabelSpinner