const printLabel = async (label) => {
    try {
        
        window.BrowserPrint.getDefaultDevice('printer', async (device) => {
            const zebraPrinter = await new window.Zebra.Printer(device)
            console.log(zebraPrinter)
            if (zebraPrinter) {
                const status = await zebraPrinter.getStatus()
                if (status.getMessage() === 'Ready') {
                    console.log('Printer ready')
                    zebraPrinter.send(label)
                }
            //
        }}, (error) => {console.log(error)})
    } catch (err) {
        throw err
    } finally {
        return {status: 'complete'}
    }
}

export default printLabel