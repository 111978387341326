import React, {useState, useEffect} from 'react'
import '../../css/SettingsModal.css'
import { motion } from 'framer-motion'
import { useLanguage } from '../../contexts/LanguageCtx'
import { useUser } from '../../contexts/UserCtx'
import { updateSettings } from '../../utils/database/dbFunction'

const SettingsModal = (props) => {

    const { toggleSettings } = props
    const language = useLanguage()
    const activeLanguage = {...language.state}
    const user = useUser()
    const [didUpdate, setDidUpdate] = useState(false)

    const handleClick = (e) => {
        if (e.target.id === 'outsideModal') {
            toggleSettings(false)
        }
    }
    const [newLanguage, setNewLanguage] = useState(user.state.settings.language)

    const handleLanguageChange = (e) => {
        console.log(e.target.value)
        setNewLanguage(e.target.value)
    }

    const handleSaveChange = async () => {
        setDidUpdate(true)
        language.dispatch({type: 'UPDATE_LANGUAGE', language: newLanguage})
        user.dispatch({type: 'UPDATE_SETTINGS', settings: { language: newLanguage}}) 
        
    }

    useEffect(() => {
        if (didUpdate) {
            const update = async () => {
                const status = await updateSettings(user.state.userName, user.state.settings)
                    if (status) {
                        console.log(status)
                        toggleSettings(false)
                    }
            }
            update()
        }
    }, [didUpdate])

    return (
        <motion.div
            key='outsideModal'
            className='SettingsModalContainer'
            id='outsideModal'
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{ delay: 0, duration: .25}}
            exit={{opacity: 0, transition: {delay: .25}}}
            onClick={(e) => handleClick(e)}
        >
            <motion.div 
                className='SettingsModal'
                id='insideModal'
                initial={{y: '-150%'}}
                animate={{y: 0}}
                exit={{y: '-150%', transition: {delay: 0}}}
                transition={{type: 'spring', damping: 15, delay: .25}}
                    
            >
                <div className='SettingsModalTitle'>
                    {activeLanguage.TitleUserSettings}
                </div>
                <div className='SettingsModalBody'>
                    <div className='SettingsModalLanguageLabel'>{activeLanguage.LabelLanguage}</div>
                    <div className='SettingsModalLanguageSelect'>
                        <select 
                            className='select-css Shadow'
                            name='labelSize'
                            id='labelSize'
                            onChange={(e) => handleLanguageChange(e)}
                            defaultValue={user.state.settings.language}
                        >   
                            <option value='English'>{activeLanguage.LabelEnglish}</option>
                            <option value='Spanish'>{activeLanguage.LabelSpanish}</option>
                        </select>
                    </div>
                    <div className='SettingsModalVersion FlexCenter'>{`${activeLanguage.LabelVersion}: 1.01`}</div>
                </div>
                <div className='SettingsModalButtonRow'>
                        <button 
                            className='SettingsModalButton Button Shadow'
                            onClick={() => handleSaveChange()}
                            >{activeLanguage.ButtonSave}</button>
                        <button 
                            className='SettingsModalButton Button Shadow'
                            onClick={() => toggleSettings(false)}
                            >{activeLanguage.ButtonCancel}</button>
                </div>
            </motion.div>
        </motion.div>
    )
}
    
    export default SettingsModal