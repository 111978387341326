import React, { useState, useEffect } from 'react'
import { getUsers, getCompanyName } from '../../utils/database/dbFunction'
import iconControls from '../../images/icons/gear.svg'
import { useAdmin } from '../../contexts/AdminCtx'

const UsersTable = (props) => {

    const [rows, setRows] = useState([])
    const [selected, setSelected] = useState(null)
    const admin = useAdmin()

    const handleSelect = (e) => {

        if (selected) {
            document.getElementById(selected.id).checked = false
            setSelected({id: e.target.id, value: e.target.value})
        } else {
            setSelected({id: e.target.id, value: e.target.value})
        }
    }

    useEffect(() => {

        if (selected) {
            document.getElementById(selected.id).checked = true
            console.log(selected.value)
            admin.dispatch({type: 'SELECT_USER', userSelected: selected.value})
        }

    }, [selected])

    useEffect(() => {
        return admin.dispatch({type: 'SELECT_USER', userSelected: null})
    }, [])

    useEffect(() => {
        const getUserData = async () => {
            try {
                const result = await getUsers()
                if (result) {
                    const promises = result.map(async (row, index) => {
                        const companyName = await getCompanyName(row.companyID)
                        if (companyName) {
                            return {...row, companyName}
                        }
                        
                    })

                    const userData = await Promise.all(promises)
                    
                    if (userData) {
                        setRows(userData)
                    }
                    
                    //setRowTotal(result.length)
                    
                }
                
            } catch (err) {
                console.log(err)
                return
            } 
        }
        getUserData()
    }, [])

    return (
        <div className='UsersTable'>
            
            <table id='UsersTable' style={{width: '100%', cellPadding: '1rem'}}>
                <thead>
                    <tr>
                    <th style={{width: '5%'}}><img src={iconControls} alt='Gear Icon' /></th>
                    <th style={{width: '30%'}}>User</th>
                    <th style={{width: '30%'}}>Company</th>
                    <th style={{width: '15%'}}>Company ID</th>
                    <th style={{width: '15%'}}>Location ID</th>
                    </tr>
                </thead>
                <tbody>
                
                {rows && rows.length > 0 && rows.map((row, index) => 

                    (
                        <tr key={index.toString()} style={index % 2 ? {background: '#dddddd'} : {background: 'white'} }>
                            <td><input 
                                type='radio' 
                                id={`rowRadio${index}`} 
                                value={row.name}
                                onClick={(e) => handleSelect(e)} 
                            /></td>
                            <td>{row.name}</td>
                            <td>{row.companyName}</td>
                            <td>{row.companyID}</td>
                            <td>{row.locationID}</td>
                        </tr>
                    )
                    )}
                
                </tbody>
            </table>
           
        </div>
    )
}

export default UsersTable