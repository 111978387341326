import React, {createContext, useContext, useReducer} from 'react'

const userState = {
    userName: '',
    userType: '',
    facilityID: null,
    facilityName: '',
    authorized: false,
    loginDateTime: null,
    settings: { }
}

const user = createContext(userState)
const { Provider } = user

const reducer = (state, action) => {

    switch(action.type) {
        case 'LOGIN':
            return { 
                ...state, 
                authorized: action.authorized,
                userType: action.userType, 
                userName: action.userName, 
                loginDateTime: action.loginDateTime, 
                facilityName: action.facilityName,
                facilityID: action.facilityID 
            }
        
        case 'LOGOUT':
            return { ...state, userName: '', userType: '', facilityID: null, facilityName: '', authorized: false, loginDateTime: null }
        
        case 'UPDATE_SETTINGS':
            return { ...state, settings: action.settings}
            
        default:
            throw new Error()
    }
}
const UserProvider = ( { children } ) => {
    const [state, dispatch] = useReducer(reducer, userState)

    return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export default UserProvider
export const useUser = () => useContext(user)