//Convert a given 16-digit QC code into it's relative count in our code database
//input param @code as String

const codeToCount = (code) => {
    
    const tempArray = code.match(/.{1,2}/g)
    
    const tempString = tempArray[7].concat(tempArray[3], tempArray[5], tempArray[4], tempArray[2], tempArray[1])
    
    return parseInt(tempString)
}

export default codeToCount