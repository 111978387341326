export const EnglishText = {
    DateFormat: 'MM/dd/yyyy',
    LabelEnglish: 'English',
    LabelSpanish: 'Spanish',
    LabelInches: 'inch',
    TabCase: 'Case',
    TabHistory: 'History',
    LabelStatus: 'Status:',
    LabelSynced: 'Synced',
    LabelOffline: 'Offline',
    LabelSyncing: 'Syncing',
    TitleCaseWindow: 'Case Info',
    LabelFirstLabel: 'First Label',
    LabelLastLabel: 'Last Label',
    ButtonAddToCase: 'Add to Case',
    TableHeaderFirstScan: 'First Scan',
    TableHeaderLastScan: 'Last Scan',
    TableHeaderQuantity: 'Quantity',
    ButtonPreview: 'Preview',
    TitleLabelWindow: 'Label Preview',
    ButtonPrintUpload: 'Print & Upload',
    TableHeaderDate: 'Date',
    TableHeaderTime: 'Time',
    TableHeaderCaseCode: 'Case Code',
    ButtonBack: 'Back',
    ButtonNext: 'Next',
    ButtonReprint: 'Reprint',
    LabelLoading: 'loading',
    ButtonSettings: 'Settings',
    ButtonLogout: 'Log Out',
    TitleUserSettings: 'User Settings',
    LabelLanguage: 'Language',
    LabelVersion: 'Version',
    ButtonSave: 'Save',
    ButtonCancel: 'Cancel'
}

export const SpanishText = {
    DateFormat: 'dd/MM/yyyy',
    LabelEnglish: 'Inglés',
    LabelSpanish: 'Español',
    LabelInches: 'pulg',
    TabCase: 'Caja',
    TabHistory: 'Historia',
    LabelStatus: 'Estado:',
    LabelSynced: 'Sincronizado',
    LabelOffline: 'Desconectado',
    LabelSyncing: 'Sincronizando',
    TitleCaseWindow: 'Información de la Caja',
    LabelFirstLabel: 'Primera Etiqueta',
    LabelLastLabel: 'Última Etiqueta',
    ButtonAddToCase: 'Agregar',
    TableHeaderFirstScan: 'Primer Escaneo',
    TableHeaderLastScan: 'Último Escaneo',
    TableHeaderQuantity: 'Cantidad',
    ButtonPreview: 'Vista Preliminar',
    TitleLabelWindow: 'Vista Preliminar del la Etiqueta',
    ButtonPrintUpload: 'Imprimir y Subir',
    TableHeaderDate: 'Fecha',
    TableHeaderTime: 'Hora',
    TableHeaderCaseCode: 'Código de Caja',
    ButtonBack: 'Regresar',
    ButtonNext: 'Seguir',
    ButtonReprint: 'Reimprimir',
    LabelLoading: 'cargando',
    ButtonSettings: 'Configuración',
    ButtonLogout: 'Cerrar Sesión',
    TitleUserSettings: 'Ajustes de Usuario',
    LabelLanguage: 'Idioma',
    LabelVersion: 'Versión',
    ButtonSave: 'Guardar',
    ButtonCancel: 'Cancelar'
}