import React, { Fragment } from 'react';
import { IconContext } from 'react-icons'
import NavContainer from './components/NavContainer'
import AppContainer from './components/AppContainer'
import WarningContainer from './components/WarningContainer'
import UpdateModal from './components/UpdateModal'
import './App.css'
import './css/Theme.css'
import StateProvider from './Store'
import UserProvider from './contexts/UserCtx'
import LanguageProvider from './contexts/LanguageCtx'
import { browserName, osName } from 'react-device-detect'
import { useServiceWorker } from './contexts/ServiceCtx'

function App() {
    const { isUpdateAvailable } = useServiceWorker()

    return (
        <UserProvider>
            <LanguageProvider>
                <StateProvider>
                    <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
                        <div className="App">
                            {browserName === 'Chrome' && osName === 'Windows' && (
                            <Fragment>
                                {isUpdateAvailable && (
                                    <UpdateModal />
                                )}
                                <NavContainer />
                                <AppContainer />
                            </Fragment>
                            )}
                        {browserName !== 'Chrome' || osName !== 'Windows' ? (
                            <WarningContainer />
                            ): null}
                        </div>
                    </IconContext.Provider>
                </StateProvider>
            </LanguageProvider>
        </UserProvider>
    );
}

export default App;
