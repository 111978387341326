import React from 'react'
import { useStore } from '../../Store'
import { useLanguage } from '../../contexts/LanguageCtx'
import { IoMdCheckmarkCircle, IoMdSync, IoMdAlert } from 'react-icons/io'

const SyncStatus = () => {

    const globalState = useStore()
    const { syncStatus } = globalState.state
    const language = useLanguage()
    const activeLanguage = {...language.state}

    if (syncStatus === 'Synced') {
        return (
            <div className='Status StatusSynced'>
                <div className='StatusLabel'>{activeLanguage.LabelStatus}</div>
                <div className='StatusSyncedText'>{activeLanguage.LabelSynced}</div>
                <IoMdCheckmarkCircle />
            </div>
        )
    }

    if (syncStatus === 'Syncing') {
        return (
            <div className='Status StatusSyncing'>
                <div className='StatusLabel'>{activeLanguage.LabelStatus}</div>
                <div className='StatusSyncingText'>{activeLanguage.LabelSyncing}</div>
                <IoMdSync />
            </div>
        )
    }

    if (syncStatus === 'Offline') {
        return (
            <div className='Status StatusOffline'>
                <div className='StatusLabel'>{activeLanguage.LabelStatus}</div>
                <div className='StatusOfflineText'>{activeLanguage.LabelOffline}</div>
                <IoMdAlert />
            </div>
        )
    }


}

export default SyncStatus