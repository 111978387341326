import React, { Fragment, useEffect } from 'react'

const HelpMenu = (props) => {
    
    const tabs = ['Signing In', 'Case Info', 'Label Preview', 'History', 'Sync Status']
    const { activeTab, setActiveTab } = props

    const handleTab = (event) => {
        event.preventDefault()
        console.log(event.currentTarget)
        setActiveTab(event.target.value)
    }

    
}

export default HelpMenu