import React from 'react'
import { motion } from 'framer-motion'
import UsersTable from './UsersTable'
import UsersButtons from './UsersButtons'

const UsersScreen = () => {

    return (
        <motion.div 
            className='UserScreenContainer AdminWindow'
            initial={{x: '200%'}}
            animate={{x: 0}}
            exit={{x: '200%'}}
            transition={{ duration: .5 }}
            >
                <UsersButtons />
                <UsersTable />
            
        </motion.div>
    )
}

export default UsersScreen