import React, { Fragment, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useStore } from '../../Store'
import { useUser } from '../../contexts/UserCtx'
import { useLanguage } from '../../contexts/LanguageCtx'
import { clearCookie } from '../../utils/server/requests'
import SyncStatus from './SyncStatus'
import iconSettings from '../../images/icons/gear.svg'
import iconLogout from '../../images/icons/logout.svg'
import SettingsModal from '../SettingsWindow/SettingsModal'

//import printLabel from '../../utils/print/printLabel'
//import HelpContainer from '../HelpWindow/HelpContainer'

const NavRight = () => {
    const [showSettings, toggleSettings] = useState(false)
    const globalState = useStore()
    const user = useUser()
    const language = useLanguage()
    const activeLanguage = {...language.state}
    const { authorized, userType } = user.state

    /* const testPrint = () => {
        printLabel()
    } */
    
    /* const testLabels = () => {
        //get two random codes from pool
        const firstCode = getRandomCode()
        let lastCode = getRandomCode()
        //make sure the codes are not the same
        while (firstCode === lastCode) {
            lastCode = getRandomCode()
        }
        //update state with new URLs
        dispatch({type: 'UPDATE_FIRST_URL', firstURL: `https://whf.ag/${firstCode}`})
        dispatch({type: 'UPDATE_LAST_URL', lastURL: `https://whf.ag/${lastCode}`})
    } */

    /* const handleHelp = () => {
        globalState.dispatch({type: 'TOGGLE_HELP'})
    } */

    const handleLogOut = () => {
        user.dispatch({type: 'LOGOUT', authorized: false})
        globalState.dispatch({type: 'RESET_STATE'})
        const logout = clearCookie()
        if (logout.status === 200) {
        window.location.reload()
        return false
        }
        
    }

    return (
        <div className='NavRight'>
            {authorized ? (
            <Fragment>
            <div 
                className='NavRightLink'
                style={{cursor: 'pointer'}}
                onClick={() => handleLogOut()}
            >
                <img src={iconLogout} alt='Logout Icon' />
                <div>{activeLanguage.ButtonLogout}</div>
            </div>
            {userType === 'user' && (
            <Fragment>
                <div 
                    className='NavRightLink' 
                    style={{cursor: 'pointer'}}
                    onClick={() => toggleSettings(!showSettings)}
                >
                    <img src={iconSettings} alt='Settings Icon' />
                    <div>{activeLanguage.ButtonSettings}</div>
                </div>
                <AnimatePresence>
                {showSettings && (
                    <SettingsModal key='SettingsModal' toggleSettings={toggleSettings} />
                )}
                </AnimatePresence>
                <div className='NavRightLink SyncLink'><SyncStatus /></div>
                {/*
                {userName === 'jlevy' && (
                <div className='NavRightLink'>
                    <button 
                        name='TestLabels'
                        type='button'
                        onClick={testLabels}
                        disabled={globalState.state.activeTab !== 'TabCase'}
                    >Test Labels</button>
                </div>
                )}
                <div className='NavRightLink'>
                    <button
                        className='NavButton'
                        onClick={handleHelp}
                    >Help</button>
                </div>
                <div className='NavRightLink'>
                    <button 
                        name='TestPrint'
                        type='button'
                        onClick={testPrint}
                    >Test Print</button>
                </div> */}
            </Fragment>
            )}
            </Fragment>
            ) : null}
        </div>
    )
}

export default NavRight


