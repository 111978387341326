import React, { Fragment, useEffect, useState } from 'react'
import AdminProvider from '../contexts/AdminCtx'
import InputContainer from './InputWindow/InputContainer'
import LabelContainer from './LabelWindow/LabelContainer'
import LoginContainer from './LoginWindow/LoginContainer'
import AdminContainer from './AdminWindow/AdminContainer'
import HelpContainer from './HelpWindow/HelpContainer'
import History from './HistoryWindow/HistoryContainer'
import ClientModal from './ClientModal'
import { useUser } from '../contexts/UserCtx'
import { useStore } from '../Store'
import { useLanguage } from '../contexts/LanguageCtx'
import { readCookie } from '../utils/server/requests'
import FullPageSpinner from './FullPageSpinner'
import { AnimatePresence } from 'framer-motion'
import { syncFacilities, getSettings, getFacility } from '../utils/database/dbFunction'
import '../css/FullPageSpinner.css'
import '../css/AppContainer.css'


const AppContainer = () => {

    const globalState = useStore()
    const { isLoading, activeTab, showHelp } = globalState.state
    const user = useUser()
    const language = useLanguage()
    const activeLanguage = {...language.state}
    const { authorized, userType, userName } = user.state
    const [clientStatus, setClientStatus] = useState(null)

    const handleTab = (event) => {
        if (event.target.name === 'TabCase') {
            globalState.dispatch({type: 'SET_ACTIVE_TAB', activeTab: 'TabCase', isDone: false})
        }
        if (event.target.name === 'TabHistory') {
            globalState.dispatch({type: 'SET_ACTIVE_TAB', activeTab: 'TabHistory'})
        }
    }

    useEffect(() => {
        setTimeout(() => {
            window.location.reload(true)
        }, 86400000)
    }, [])

    useEffect(() => {
        if (authorized) {
        syncFacilities()
        }
    }, [authorized]);

    /* useEffect(() => {
        if (userName) {
        syncSettings(userName)
        }
    }, [userName]) */

    useEffect(() => {
        if (userName) {
        async function getUserData() { 
            const settings = await getSettings(userName)
                if (settings) {
                    //console.log(settings)
                    user.dispatch({type: 'UPDATE_SETTINGS', settings})
                }
        }
        getUserData()
    }
    }, [userName])

    useEffect(() => {
        //console.log(user.state.settings)
        if (userName) {
        language.dispatch({type: 'UPDATE_LANGUAGE', language: user.state.settings.language})
        }
    }, [user.state.settings])

    useEffect(() => {
        
        const checkCookie = async () => {
            try {
                const request = await readCookie()
                
                if (request && request.status === 200) {
                    globalState.dispatch({type: 'START_LOADING'})
                    const userName = request.data.userCtx.name
                    const facilityData = await getFacility(userName)
                    if (facilityData) {
                        setTimeout(() => {
                        const { facilityID, facilityName } = facilityData
                        user.dispatch({type: 'LOGIN', userName, userType: 'user', facilityID, facilityName, authorized: true, loginDateTime: new Date() })
                        globalState.dispatch({type: 'END_LOADING'})
                        }, 1000)
                    } else {
                        if (userName === 'admin') {
                            user.dispatch({type: 'LOGIN', userName, userType: 'admin', facilityID: 999, facilityName: 'Admin Facility', authorized: true, loginDateTime: new Date() })
                            globalState.dispatch({type: 'END_LOADING'})
                            return
                        }
                        console.log('Error: no facility ID found')
                        globalState.dispatch({type: 'END_LOADING'})
                    }
                }     
            } catch (err) {
                throw err
            }
        }
        checkCookie()
        
    }, [])

    useEffect(() => {
        
        const getClientInfo = async () => {
            await window.BrowserPrint.getApplicationConfiguration((config) => {
                if (config) {
                    setClientStatus(true)
                } else {
                    setClientStatus(false)
                }  
            }, (error) => {
                console.log(error)
                setClientStatus(false)
            })
        }
        getClientInfo()
    }, [])

    useEffect(() => {
        if (clientStatus) {
            console.log(`Browser Print running: ${clientStatus}`)
        } 
    }, [clientStatus])

    return (
        <div className='AppContainer'>
            {/*<div className='TotalLabels'>Total: {totalLabels}</div>*/}
            <FullPageSpinner disabled={!isLoading} message='loading' />
            {!authorized && (
                <LoginContainer />
            )}
            <AnimatePresence>
            {showHelp && (
            
                <HelpContainer key='HelpContainer' />
            
            )}
            </AnimatePresence>
            {authorized && userType === 'user' && (
            <Fragment>
                <div className='TabViewCase'>
                    <button 
                    className={activeTab === 'TabCase' ? 'TabButtonCase Tab ActiveTab' : 'TabButtonCase Tab'}
                    name='TabCase'
                    onClick={(e) => handleTab(e)}
                    >{activeLanguage.TabCase}</button>
                </div>
                <div className='TabViewHistory'>
                    <button 
                    className={activeTab === 'TabHistory' ? 'TabButtonHistory Tab ActiveTab' : 'TabButtonHistory Tab'}
                    name='TabHistory'
                    onClick={(e) => handleTab(e)}
                    >{activeLanguage.TabHistory}</button>
                </div>
            
                <Fragment>
                <AnimatePresence initial={false} >
                    <LabelContainer key={'LabelContainer'} />
                {activeTab === 'TabCase' ? (
                    <InputContainer key={'InputContainer'} />
                ): null}
                {activeTab === 'TabHistory' ? (
                    <History key={'HistoryContainer'} />
                ): null}
                </AnimatePresence>
                </Fragment>
            </Fragment>
            )}
            {authorized && userType === 'admin' && (
                <AdminProvider>
                    <AdminContainer />
                </AdminProvider>
            )}
            {!clientStatus && authorized && (
                <ClientModal />
            )}
        </div>
            
    )
}

export default AppContainer